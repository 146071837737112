import React from 'react'

import {_data} from 'Pages/Home/data'

import { useNavigate } from 'react-router-dom'
import { getImage, truncate } from 'Components/getImage'


export default function List(props) {
    
    const {blogList = []} = props
    const blogs = blogList

    const navigate = useNavigate()

    return (
        <>
        <div className="">
            <div className="grid grid-cols-1 sm:grid-cols-4 gap-[5px] sm:gap-[20px] ">
                    {
                        blogs.map(item => (
                            <div 
                                onClick={() => navigate(`/blog/${item.id}`)}
                                className="border-[15px] bg-white border-white _border-[#5d64ef] rounded-[5px] drop-shadow-xl cursor-pointer" key={item.id}>
                                <img src={getImage(item.thumbnail, "/images/bg/stock.png")} />
                                <div className="text-black bg-white relative z-[10] pt-[5px] font-bold">
                                             {truncate(item.heading)} 
                                        </div>
                            </div>
                        ))
                    }
                </div>
        </div>
            
        </>
    )
}
