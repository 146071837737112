import Parallax from 'Components/Parallax'
import React, { useState, useEffect } from 'react'

import { _data } from '../data'
import { motion } from 'framer-motion'
import { SlideBottom, SlideTop } from 'Components/SlideAnimation'

export default function Index(props) {

    const serviceList = _data?.home?.services?.serviceList || []

    const [currentService, setCurrentService] = useState(1)

    const updateCurrent = () => {

        // console.log(currentService)
        setCurrentService(currentService => {
             if(currentService === 4){
                return 1
             }
             return currentService + 1

        });
    }

    useEffect(() => {
        const interval = setInterval(() => {
            updateCurrent()
        }, 3000);
        return () => clearInterval(interval);
    }, []);

    useEffect(()=> {

    }, [])


    const ServiceListRender = () => (<div>
        {
            serviceList?.map((item, index) => (<div key={item.id}>
                <div className="py-[15px] ">
                    <div
                        onClick={() => setCurrentService(item.id)}
                        className={`relative  font-bold transition-all ${item.id === currentService ? " text-[18px] sm:text-[25px] " : " sm:text-[20px]"}`}>
                        {/* <Parallax offset={20 + ((1 + index) * 10)}> */}
                        <div className="cursor-pointer">
                            {item.label}
                        </div>
                        {/* </Parallax> */}
                        {item.id === currentService &&
                            <>
                                <motion.div layoutId='service_underline' className='absolute max-w-[230px]  h-[5px] w-full bg-[#A8CF45]  top-[40px]' ></motion.div>
                                <motion.div layoutId='service_arrow' className='absolute right-[-90%] sm:right-[0px] top-0 z-[40] '>
                                    <svg  fill='white' width="50px" clipRule="evenodd" fillRule="evenodd" strokeLinejoin="round" strokeMiterlimit="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="m10.211 7.155c-.141-.108-.3-.157-.456-.157-.389 0-.755.306-.755.749v8.501c0 .445.367.75.755.75.157 0 .316-.05.457-.159 1.554-1.203 4.199-3.252 5.498-4.258.184-.142.29-.36.29-.592 0-.23-.107-.449-.291-.591-1.299-1.002-3.945-3.044-5.498-4.243z" /></svg>
                                </motion.div>
                            </>
                        }
                    </div>
                </div>
            </div>))
        }
    </div>)

    const Graphic_web_design = () => {

        return (<div className='relative min-h-[300px]'>
            <Parallax offset={20}>
                <div className="absolute   w-[300px] h-[300px] spin-5000 ">
                    <div className="absolute w-[20px] h-[20px] bg-white rounded-full drop-shadow"></div>
                    <div className="absolute  w-[20px] h-[20px] right-0 bottom-0 bg-white rounded-full drop-shadow"></div>
                </div>
            </Parallax>
            <div>
                <SlideBottom className='absolute  translate-x-[-25%]'>
                    {
                        currentService === 1 && <svg className='' width="200px" height="200px" fill='#A8CF45' viewBox="0 0 24 24"><path d="M22 3.2c0-.663-.537-1.2-1.2-1.2h-17.6c-.663 0-1.2.537-1.2 1.2v11.8h20v-11.8zm-2 9.8h-16v-9h16v9zm2 3h-20c-.197.372-2 4.582-2 4.998 0 .522.418 1.002 1.002 1.002h21.996c.584 0 1.002-.48 1.002-1.002 0-.416-1.803-4.626-2-4.998zm-12.229 5l.467-1h3.523l.467 1h-4.457z" /></svg>
                    }
                    {
                        currentService === 2 && 
                        // <svg className='' width="200px" height="200px" fill='#A8CF45' viewBox="0 0 24 24"><path d="M22 3.2c0-.663-.537-1.2-1.2-1.2h-17.6c-.663 0-1.2.537-1.2 1.2v11.8h20v-11.8zm-2 9.8h-16v-9h16v9zm2 3h-20c-.197.372-2 4.582-2 4.998 0 .522.418 1.002 1.002 1.002h21.996c.584 0 1.002-.48 1.002-1.002 0-.416-1.803-4.626-2-4.998zm-12.229 5l.467-1h3.523l.467 1h-4.457z" /></svg>
                        <svg xmlns="http://www.w3.org/2000/svg"  width="200px" height="200px" fill='#A8CF45' viewBox="0 0 24 24"><path d="M4 21.832c4.587.38 2.944-4.493 7.188-4.538l1.838 1.534c.458 5.538-6.315 6.773-9.026 3.004zm14.065-7.115c1.427-2.239 5.847-9.749 5.847-9.749.352-.623-.43-1.273-.976-.813 0 0-6.572 5.714-8.511 7.525-1.532 1.432-1.539 2.086-2.035 4.447l1.68 1.4c2.227-.915 2.868-1.039 3.995-2.81zm-11.999 3.876c.666-1.134 1.748-2.977 4.447-3.262.434-2.087.607-3.3 2.547-5.112 1.373-1.282 4.938-4.409 7.021-6.229-1-2.208-4.141-4.023-8.178-3.99-6.624.055-11.956 5.465-11.903 12.092.023 2.911 1.081 5.571 2.82 7.635 1.618.429 2.376.348 3.246-1.134zm6.952-15.835c1.102-.006 2.005.881 2.016 1.983.004 1.103-.882 2.009-1.986 2.016-1.105.009-2.008-.88-2.014-1.984-.013-1.106.876-2.006 1.984-2.015zm-5.997 2.001c1.102-.01 2.008.877 2.012 1.983.012 1.106-.88 2.005-1.98 2.016-1.106.007-2.009-.881-2.016-1.988-.009-1.103.877-2.004 1.984-2.011zm-2.003 5.998c1.106-.007 2.01.882 2.016 1.985.01 1.104-.88 2.008-1.986 2.015-1.105.008-2.005-.88-2.011-1.985-.011-1.105.879-2.004 1.981-2.015zm10.031 8.532c.021 2.239-.882 3.718-1.682 4.587l-.046.044c5.255-.591 9.062-4.304 6.266-7.889-1.373 2.047-2.534 2.442-4.538 3.258z"/></svg>
                    }
                    {
                        currentService === 3 && 
                        // <svg className='' width="200px" height="200px" fill='#A8CF45' viewBox="0 0 24 24"><path d="M22 3.2c0-.663-.537-1.2-1.2-1.2h-17.6c-.663 0-1.2.537-1.2 1.2v11.8h20v-11.8zm-2 9.8h-16v-9h16v9zm2 3h-20c-.197.372-2 4.582-2 4.998 0 .522.418 1.002 1.002 1.002h21.996c.584 0 1.002-.48 1.002-1.002 0-.416-1.803-4.626-2-4.998zm-12.229 5l.467-1h3.523l.467 1h-4.457z" /></svg>
                        <svg xmlns="http://www.w3.org/2000/svg"  width="150px" height="150px" fill='#A8CF45' viewBox="0 0 24 24"><path d="M24 0l-6 22-8.129-7.239 7.802-8.234-10.458 7.227-7.215-1.754 24-12zm-15 16.668v7.332l3.258-4.431-3.258-2.901z"/></svg>
                    }
                    {
                        currentService === 4 && 
                        // <svg className='' width="200px" height="200px" fill='#A8CF45' viewBox="0 0 24 24"><path d="M22 3.2c0-.663-.537-1.2-1.2-1.2h-17.6c-.663 0-1.2.537-1.2 1.2v11.8h20v-11.8zm-2 9.8h-16v-9h16v9zm2 3h-20c-.197.372-2 4.582-2 4.998 0 .522.418 1.002 1.002 1.002h21.996c.584 0 1.002-.48 1.002-1.002 0-.416-1.803-4.626-2-4.998zm-12.229 5l.467-1h3.523l.467 1h-4.457z" /></svg>
                        <svg xmlns="http://www.w3.org/2000/svg" width="200px" height="200px" fill='#A8CF45' viewBox="0 0 24 24"><path d="M10 19.5c0 .829-.672 1.5-1.5 1.5s-1.5-.671-1.5-1.5c0-.828.672-1.5 1.5-1.5s1.5.672 1.5 1.5zm3.5-1.5c-.828 0-1.5.671-1.5 1.5s.672 1.5 1.5 1.5 1.5-.671 1.5-1.5c0-.828-.672-1.5-1.5-1.5zm1.336-5l1.977-7h-16.813l2.938 7h11.898zm4.969-10l-3.432 12h-12.597l.839 2h13.239l3.474-12h1.929l.743-2h-4.195z"/></svg>
                    }
                </SlideBottom>
            </div>
            <SlideTop offset={90}>
                <svg className='absolute right-0 top-[180px]' width="100px" height="100px" fill='#A8CF45' viewBox="0 0 24 24"><path d="M19.479 10.092c-.212-3.951-3.473-7.092-7.479-7.092s-7.267 3.141-7.479 7.092c-2.57.463-4.521 2.706-4.521 5.408 0 3.037 2.463 5.5 5.5 5.5h13c3.037 0 5.5-2.463 5.5-5.5 0-2.702-1.951-4.945-4.521-5.408zm-8.479 6.908l.58-3h-2.496l3.916-5-.912 3h2.828l-3.916 5z" /></svg>
            </SlideTop>

        </div>)
    }

    const SelectedGraphic = () => (<div className='relative '>
        <div className="absolute w-[500px] h-[500px] top-[-100px] left-[-140px]  bg-gradient-to-r from-[#58549C] via-[#837DE8] to-[#58549C] rounded-full translate-x-[25%]  "></div>
        <div className="absolute w-[400px] h-[400px] top-[-50px] left-[-70px]  bg-gradient-to-r from-[#34325C] via-[#494682] to-[#34325C] rounded-full translate-x-[25%]  "></div>
        <div className="absolute w-[300px] h-[300px]  bg-gradient-to-r from-[#5F5BA8] via-[#837DE8] to-[#5F5BA8] rounded-full translate-x-[25%] ">
            <Graphic_web_design />
        </div>
    </div>)


    return (<>
    <div className='h-[50px] w-full bg-[#58549C] drop-shadow'> </div>
        <div className="  bg-gradient-to-b from-[#837DE8] to-[#5F5BA8] w-full">
            <div className="container mx-auto px-[20px] sm:px-[50px]">

                <div className="py-[120px]">
                    <div className="flex">
                        <div className="w-1/2 text-white">
                            <div className="text-[40px] font-bold relative z-[20]">
                                <div offset={40}>
                                    OUR SERVICES
                                </div>
                            </div>
                            <div className='relative z-[20] pt-[0px] '>
                                <ServiceListRender />
                            </div>
                        </div>
                        <div className="w-1/2">
                            <SelectedGraphic />
                        </div>
                    </div>
                </div>

            </div>

        </div>
    </>

    )
}
