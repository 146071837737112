import React, { useState } from 'react'
import TextareaAutosize from 'react-textarea-autosize';

import { useNavigate } from 'react-router-dom'

import PrimaryButton from 'Components/PrimaryButton'


export default function Form(props) {
    const navigate = useNavigate()
    
    const [value, setValues] = useState({
        name : "",
        email : "",
        company : "",
        details : ""
    })

    const handleChange = (e) => {
        // console.log(e)
        setValues(prev =>({
            ...prev,
           [e.target.name] : e.target.value
        }))
    }

    const CustomRedirect = () => {

        navigate(`/pricing?${value?.name && `name=${value?.name}`}${value?.email && `&&email=${value?.email}`}${value?.company && `&&company=${value?.company}`}${value?.details && `&&details=${value?.details}`}#quote`)
    }


    return (
        <>
         <div>
            <div>
                <TextField  name="name" onChange={handleChange} value={value?.name} placeholder="Your name" />
            </div>
            <div>
                <TextField  name="email" onChange={handleChange} value={value?.email} placeholder="Your email" />
            </div>
            <div>
                <TextField  name="company" onChange={handleChange} value={value?.company} placeholder="Your business name" />
            </div>
            <div>
                <TextArea  name="details" onChange={handleChange} value={value?.details} placeholder="Details" />
            </div>
            <div>
            
            </div>
        </div>   
        <div className='pt-[30px]'>
            <PrimaryButton 
                onClick={() =>  CustomRedirect()}
                label="GET A QUOTE" className="text-[16px] "  />
        </div>
        </>
    )
}

export const TextField = (props) => {

    const {name, onChange=()=> {}, value, placeholder=""} = props

    return(<input 
        className='mt-[20px] border-0 bg-transparent text-white  border-b-2 focus:outline-0 placeholder:text-slate-400 placeholder:text-[16px] w-full_  min-w-[250px] sm:min-w-[350px]'
        name={name}
        onChange={onChange}
        value={value}   
        placeholder={placeholder} 
    />)
}

export const TextArea = (props) => {

    const {name, onChange=()=> {}, value, placeholder=""} = props

    return(<TextareaAutosize 
        className='mt-[20px] border-0 bg-transparent text-white  border-b-2 focus:outline-0 placeholder:text-slate-400 placeholder:text-[16px] w-full_ min-w-[250px] sm:min-w-[350px]'
        name={name}
        onChange={onChange}
        value={value}   
        placeholder={placeholder} 
        maxRows={10}
    />)
}