import React, { useState, useEffect } from 'react'
// libs
import { motion } from 'framer-motion'
import { useLocation } from 'react-router-dom'

import NavLinks from './NavLinks'
import Logo from './Logo'

export default function Desktop(props) {

    const { transparent = false, floatActivate = false, isfloatingNav = true, navData = {}, openSideBar = () => { } } = props


    const _navbar = navData?.navbar || {}
    const location = useLocation()

    const [isUnderlined, setUnderlined] = useState("/")

    const makeUnderlined = (id) => {
        setUnderlined(id)
    }

    const resetUnderlined = (delay = 0) => {
        setTimeout(() => {
            setUnderlined(location.pathname)
        }, delay)
    }

    useEffect(() => {
        resetUnderlined()
    }, [])


    return (
        <>
            <div className={` 
          ${isfloatingNav ?
                    `fixed w-full   z-[200] ${floatActivate ? 'translate-y-0 drop-shadow-md  bg-[#58549C]/50 backdrop-blur-xl ' : '-translate-y-0  bg-[#58549C]/50 backdrop-blur-xl '}`
                    : ''
                } 
            transition-all duration-1000
            `}>
                <div className=" container mx-auto px-[20px] sm:px-[50px]">
                    <div className="flex justify-between py-[20px] items-center">
                        <NavLinks navData={navData} openSideBar={openSideBar} />
                        <Logo navData={navData} />
                    </div>
                </div>
            </div>
        </>
    )
}
