import React from 'react'
import { useNavigate } from 'react-router-dom'
// import { _data } from '../data'
import Logo_Image from 'Assets/Logo/green.png'


export default function Logo(props) {

    const {navData} = props
    
    const _navbar = navData || {}
    const navigate = useNavigate()

    return (
        <>
        <div onClick={() => navigate("/")} className="cursor-pointer">

        {_navbar?.logo ? 
            <img src={_navbar?.logo} />  
            :
            <div className="text-[40px] text-white font-bold">
               <img src={Logo_Image} alt="Soulcode solutions" className='h-[70px] w-[100px]'/>
            </div>
        }  
        </div>
        </>
    )
}
