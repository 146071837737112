import React from 'react'

export default function Contact(props) {

    const Icon_email = () => (
        <svg xmlns="http://www.w3.org/2000/svg" fill='#A8CF45' width="24" height="24" viewBox="0 0 24 24"><path d="M13.718 10.528c0 .792-.268 1.829-.684 2.642-1.009 1.98-3.063 1.967-3.063-.14 0-.786.27-1.799.687-2.58 1.021-1.925 3.06-1.624 3.06.078zm10.282 1.472c0 6.627-5.373 12-12 12s-12-5.373-12-12 5.373-12 12-12 12 5.373 12 12zm-5-1.194c0-3.246-2.631-5.601-6.256-5.601-4.967 0-7.744 3.149-7.744 7.073 0 3.672 2.467 6.517 7.024 6.517 2.52 0 4.124-.726 5.122-1.288l-.687-.991c-1.022.593-2.251 1.136-4.256 1.136-3.429 0-5.733-2.199-5.733-5.473 0-5.714 6.401-6.758 9.214-5.071 2.624 1.642 2.524 5.578.582 7.083-1.034.826-2.199.799-1.821-.756 0 0 1.212-4.489 1.354-4.975h-1.364l-.271.952c-.278-.785-.943-1.295-1.911-1.295-2.018 0-3.722 2.19-3.722 4.783 0 1.73.913 2.804 2.38 2.804 1.283 0 1.95-.726 2.364-1.373-.3 2.898 5.725 1.557 5.725-3.525z"/></svg>
    )

    const Icon_location = () => (
        <svg xmlns="http://www.w3.org/2000/svg" fill='#A8CF45' width="24" height="24" viewBox="0 0 24 24"><path d="M12 1c-3.148 0-6 2.553-6 5.702 0 3.148 2.602 6.907 6 12.298 3.398-5.391 6-9.15 6-12.298 0-3.149-2.851-5.702-6-5.702zm0 8c-1.105 0-2-.895-2-2s.895-2 2-2 2 .895 2 2-.895 2-2 2zm12 14h-24l4-8h3.135c.385.641.798 1.309 1.232 2h-3.131l-2 4h17.527l-2-4h-3.131c.435-.691.848-1.359 1.232-2h3.136l4 8z"/></svg>
    )
    
    const RenderDetails = (props) => {
        const {Icon = () => <></>, label} = props
        return(
            <div className='flex items-center'>
                <div className='mr-[15px]'>
                    <Icon />
                </div>
                <div className='text-white text-[16px] font-bold' >
                {label}
                </div>
            </div>
        )
    }

    return (
        <>
           <div>
                <div >
                    <RenderDetails label="info@soulcodesolutions.com" Icon={Icon_email} />
                </div>
                <div className='pt-[20px]'>
                    <RenderDetails label="USA" Icon={Icon_location} />
                </div>
                <div className='pt-[20px]'>
                    <RenderDetails label="Armenia" Icon={Icon_location} />
                </div>
                <div className='pt-[20px]'>
                    <RenderDetails label="India" Icon={Icon_location} />
                </div>
            </div> 
        </>
    )
}
