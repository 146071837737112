import Form from 'Pages/Home/Contact/Form'
import React, { useEffect, useState } from 'react'

import { TextArea, TextField } from 'Pages/Home/Contact/Form'
import PrimaryButton from 'Components/PrimaryButton'

//  form 
import * as yup from 'yup';
import axios from 'axios'
import { useSearchParams } from 'react-router-dom';
import { Dna } from 'react-loader-spinner'


export default function Quote(props) {

    const [searchParams] = useSearchParams()

    const initialValues = {
        package: "basic",
        is_design_needed: "false",
        design_type: 'custom',
        admin_dash: false,
        blog: false,
        form: false,
        payment: false,
        dynamic: true,
        hosting: "false",
        name: "",
        email: "",
        company: "",
        details: ""
    }

    const [inputValue, setInputValue] = useState(initialValues)

    const [loading, setLoading] = useState(false)
    const [error, setError] = useState("")
    const [success, setSuccess] = useState(false)

    const Schema = yup.object({
        company : yup.string().required("Company name is required"),
        email: yup.string().required("Email is a required field").email("Invalid email"),
        name : yup.string().required("Name is required"),
        // phone : yup.string().required("Phone number is required"),

    });

    useEffect(() => {
        const _temp = {
            name : searchParams.get("name") || "",
            email : searchParams.get("email") || "",
            company : searchParams.get("company") || "",
            details : searchParams.get("details") || "",
        }
        setInputValue(prev => ({
            ...prev,
            ..._temp
        }))
        

    }, [])




    const handleChange = (e) => {
        // console.log(e)
        setInputValue(prev => ({
            ...prev,
            [e.target.name]: e.target.value
        }))
        setError("")
    }
    


    const handleRadioChange = (e) => {
        // console.log(e.target.name)
        setInputValue(prev => ({
            ...prev,
            [e.target.name]: e.target.value
        }))
    }

    const handleCheckChange = (e) => {
        // console.log(e.target.checked)
        setInputValue(prev => ({
            ...prev,
            [e.target.name]: !inputValue?.[e.target.name]
        }))
    }

    const RadioButton = ({ name, value, label, disabled = false }) => {
        return (<label className='flex items-center pr-[20px]' >
            <input type="radio" name={name} value={value} checked={inputValue?.[name] === value} disabled={disabled}
                onChange={(e) => handleRadioChange(e)}
                className='accent-[#A8CF45] w-[20px] h-[20px] checked:accent-[#A8CF45]' />
            <span className="pl-[10px] text-white text-[14px]">{label}</span>

        </label>)
    }

    const CheckButton = ({ name, label, disabled = false }) => {
        return (<label className='flex items-center pr-[20px]' >
            <input type="checkbox" name={name} checked={inputValue?.[name] === true} disabled={disabled}
                onChange={(e) => handleCheckChange(e)}
                className='accent-[#A8CF45] w-[20px] h-[20px] checked:accent-[#A8CF45]' />
            <span className="pl-[10px] text-white text-[14px]">{label}</span>

        </label>)
    }

    const SubmitForm = async() => {
        const _URL = "http://localhost:1337"
        setError("")
        try{
            setLoading(true)
            await Schema.validate({...inputValue})

            const payload = {
                userData : inputValue
            }

            const res = await axios.post(`${process.env.REACT_APP_URL || _URL}/api/contact/mail`, payload)
            console.log(res)
            setSuccess(true)

        } catch(ex){
            setError(ex?.errors || ex?.response?.data?.error?.message ||"Something went wrong")
            setLoading(false)
        }
        setLoading(false)
    }

    const SuccessMessage = ({}) => (<div>
        <div className="fixed left-0 top-0 z-[5] h-screen w-full bg-black/50 flex flex-col items-center justify-center">
            <div className="w-full max-w-[400px] bg-[#58549C] rounded-xl overflow-hidden">
                <div className="h-[50px] w-full  "></div>
                <div className="min-h-[100px] bg-white">
                    <div className="py-[10px] text-center">
                       Thank you, <br/>
                        We'll contact you soon :)
                    <PrimaryButton onClick={() => setSuccess(false)} label="OK"  className="mx-auto my-[10px] "/>
                    </div>
                </div>
            </div>
        </div>
    </div>)

    return (
        <>  
            {success &&
            <SuccessMessage />
            }
            <div className=''>
                <div className=" text-white pb-[10px]">
                    Please select your preffered package
                </div>
                <div className="radio flex flex-wrap">
                    <RadioButton value={"basic"} name="package" label={"Basic"} />
                    <RadioButton value={"medium"} name="package" label={"Medium"} />
                    <RadioButton value={"premium"} name="package" label={"Premium"} />
                    <RadioButton value={"ultra"} name="package" label={"Ultra"} />
                </div>
                <div className=" text-white pb-[10px] pt-[20px]">
                    Do you want us to do the design?
                </div>
                <div className="radio flex flex-wrap">
                    <RadioButton value={"true"} name="is_design_needed" label={"Yes"} />
                    <RadioButton value={"false"} name="is_design_needed" label={"No"} />

                </div>
                {
                    inputValue.is_design_needed === "true" &&
                    <>
                        <div className=" text-white pb-[10px] pt-[20px]">
                            Which type of design do you need?
                        </div>
                        <div className="radio flex flex-wrap">
                            <RadioButton value={"template"} name="design_type" label={"I'll choose from a template"} disabled={inputValue.is_design_needed === "false"} />
                            <RadioButton value={"custom"} name="design_type" label={"I need fully customized one"} disabled={inputValue.is_design_needed === "false"} />

                        </div>
                    </>
                }
                <div className=" text-white pb-[10px] pt-[20px]">
                    Pick the features you need?
                </div>
                <div className="radio flex flex-wrap">
                    <CheckButton name="dynamic" label={"Dynamic contents"} />
                    <CheckButton name="admin_dash" label={"Admin Dash"} />
                    <CheckButton name="blog" label={"Blog/News"} />
                    <CheckButton name="form" label={"Form submition"} />
                    <CheckButton name="payment" label={"Payment Integraion"} />

                </div>
                <div className=" text-white pb-[10px] pt-[20px]">
                    Do you need hosting service?
                </div>
                <div className="radio flex flex-wrap">
                    <RadioButton value={"true"} name="hosting" label={"Yes"} />
                    <RadioButton value={"false"} name="hosting" label={"No"} />

                </div>
                <div className="pt-[40px]">
                    <div>
                        <div>
                            <TextField name="name" onChange={handleChange} value={inputValue?.name} placeholder="Your name" />
                        </div>
                        <div>
                            <TextField name="email" onChange={handleChange} value={inputValue?.email} placeholder="Your email" />
                        </div>
                        <div>
                            <TextField name="company" onChange={handleChange} value={inputValue?.company} placeholder="Your business name" />
                        </div>
                        <div>
                            <TextArea name="details" onChange={handleChange} value={inputValue?.details} placeholder="Details" />
                        </div>
                        <div>

                        </div>
                    </div>
                </div>
        {/* {console.log(inputValue)} */}
                
                <div className="text-sm pt-[10px] text-red-400">
                    {`${error}`}
                </div>
                
                <div className="pt-[20px]">
                    {loading ? 
                    <div className='py-[0px]'>
                            <Dna
                            height="60"
                            width="60"
                            // color="#595EA4"
                            // secondaryColor='#7CC7DE'
                            radius='12.5'
                            ariaLabel="mutating-dots-loading"
                            wrapperStyle={{}}
                            wrapperClass=""
                            visible={true}
                        />
                    </div>
                    :
                    <PrimaryButton 
                    onClick={() => SubmitForm() }
                    label="GET QUOTE" className="text-[16px] "  />
                    }
                </div>
            </div>

        </>
    )
}
