import React, { useState, useEffect } from 'react'

import MarkdownView from 'react-showdown'

import { useScrollBehaviours } from 'Components/useScrollBehaviours'

import { Dna } from 'react-loader-spinner'


const qs = require('qs');


export default function View(props) {

    const { id } = props
    const { ScrollToTop } = useScrollBehaviours()


    const [newsData, setNewsData] = useState({})
    const [loading, setloading] = useState(true)

    const query = qs.stringify({
        populate: {
            thumbnail: true,
        }
    })

    // fetch data using id
    const fetchData = async () => {
        let url = `${process.env.REACT_APP_URL}/api/blogs/${id}?${query}`

        try {
            setloading(true)
            const res = await fetch(`${url}`)
            const data = await res.json()
            setNewsData(data?.data?.attributes)
            setloading(false)
        } catch (ex) {
            console.log(ex)
            // setloading(false)
        }
    }

    useEffect(() => {
        fetchData()
        ScrollToTop()
    }, [])

    return (
        <>
            <div className="">
                <div className="">
                    {loading ? <div className='w-fit text-center mx-auto'>
                        <Dna
                            height="100"
                            width="100"
                            // color="#595EA4"
                            // secondaryColor='#7CC7DE'
                            radius='12.5'
                            ariaLabel="mutating-dots-loading"
                            wrapperStyle={{}}
                            wrapperClass=""
                            visible={true}
                        />
                        <div className="text-[16px] pt-[0px] text-white">
                            Loading...
                        </div>
                    </div>
                        :
                        <div className="">
                            <div className="text-white text-[32px] lg:text-[48px] pb-[20px]">
                                <MarkdownView markdown={newsData?.heading} className="" />
                            </div>
                            <div className="text-black bg-white p-3 shadow rounded-xl py-[20px] relative">
                                <div className="absolute right-0 top-[-15px]">
                                    <svg className='mr-0' xmlns="http://www.w3.org/2000/svg" fill='#ed3833' width="35" height="35" viewBox="0 0 24 24"><path d="M6.166 16.943l1.4 1.4-4.622 4.657h-2.944l6.166-6.057zm11.768-6.012c2.322-2.322 4.482-.457 6.066-1.931l-8-8c-1.474 1.584.142 3.494-2.18 5.817-3.016 3.016-4.861-.625-10.228 4.742l9.6 9.6c5.367-5.367 1.725-7.211 4.742-10.228z"/></svg>
                                </div>
                                <MarkdownView markdown={newsData?.content} className="" />
                            </div>
                            <hr className='my-[50px]'/>
                        </div>
                    }
                </div>
            </div>
        </>
    )
}
