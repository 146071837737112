import React from 'react'

export default function PrimaryButton(props) {
    
    const {label="", onClick=()=>{}, className = "", color = "bg-[#A8CF45] text-white", loading = false } = props

    return (
        <>
           <div  
           onClick={onClick}
           className={`w-fit px-[20px] py-[10px]  font-bold  cursor-pointer rounded-[20px] ${className} ${color}`}> 
            {label}
            </div> 
        </>
    )
}
