import React from 'react'

import { _data } from '../data'

import _work from 'Assets/bg/work_4.jpg'
import { useNavigate } from 'react-router-dom'

import useStoreData from 'store/storeData'
import { getImage, truncate } from 'Components/getImage'


export default function Index(props) {

    // const blogs = _data?.home?.blogs
    const { storeData } = useStoreData()
    const blogList = storeData?.homePage?.blogs || []

    let blogs = blogList.slice(0, 6) || []

    const navigate = useNavigate()

    return (
        <div

            className='_bg-[#f2f1ff] bg-black'>
            <div className='h-[50px] w-full bg-[#34325C] drop-shadow relative z-[100]'>
                <div
                    onClick={() => navigate("/blog")}
                    className="absolute cursor-pointer h-[70px] min-w-[100px] bg-[#837DE8]/80 backdrop-blur-xl drop-shadow-xl rounded-[10px] top-[-50px] left-[20px] sm:left-[100px] flex flex-col items-center justify-center font-bold text-[20px] text-white">
                    BLOG
                </div>
            </div>
            <div className='min-h-[300px] relative'>
                <div
                    style={{
                        backgroundImage: `url(${_work})`,
                        backgroundAttachment: "fixed",
                        backgroundSize: "contain"
                    }}
                    className="absolute  blur-[20px] h-full w-full perfomance_fix "></div>
                <div className='absolute w-full h-full bg-black/60'></div>
                <div className="container mx-auto  px-[50px]">

                    <div className="grid grid-cols-1 sm:grid-cols-3 gap-[20px] sm:gap-[20px] py-[50px]">
                        {
                            blogs.map(item => (
                                <div onClick={() => navigate(`/blog/${item.id}`)} className="cursor-pointer">
                                    <div

                                        className="border-[5px] sm:border-[15px] border-white _border-[#5d64ef] bg-[#58549C] rounded-[5px] drop-shadow-xl" key={item.id}>
                                        <img src={getImage(item.thumbnail, "/images/bg/stock.png")} className='opacity-[0.4]' />
                                        <div className="text-black bg-white relative z-[10] pt-[5px] font-bold">
                                             {truncate(item.heading)} 
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>
            {/* <div className='h-[50px] w-full bg-[#7780f4] drop-shadow'> </div> */}
        </div>
    )
}
