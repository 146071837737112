import React from 'react'

import Hero from './Hero'
import Projects from './Projects'
import Services from './Services'
import Blog from './Blog'
import Contact from './Contact'

import Navbar from 'Components/Navbar'

export default function Home(props) {


    return (
        <>
            <div className='w-full overflow-x-hidden'>
                <Navbar />
                <Hero />
                <Projects />
                <Services />
                <Blog />
                <Contact />
                {/* <div className="h-screen"></div> */}
            </div>
        </>
    )
}
