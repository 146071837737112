import React from 'react'
import ProtfolioCards from 'Components/ProtfolioCards'

import { _data } from '../data'
import bg from 'Assets/bg/work.jpg'
import _work from 'Assets/bg/work_4.jpg'


import Grass_home from 'Assets/home/_Grass_home.svg'
import Home_grasshome from 'Assets/home/_Home_grasshome.svg'
import Parallax from 'Components/Parallax'
import { Link } from 'react-router-dom'


export default function Index(props) {

    const projectList = _data?.home?.projectLists || []

    const _style = {
        _bg: {
            backgroundImage: `url(${_work})`,
            backgroundSize: "contain",
            backgroundAttachment: "fixed",
        }
    }

    const ArrowRight = () => <svg clip-rule="evenodd" width={"60px"} fill="white" fill-rule="evenodd" stroke-linejoin="round" stroke-miterlimit="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="m14.523 18.787s4.501-4.505 6.255-6.26c.146-.146.219-.338.219-.53s-.073-.383-.219-.53c-1.753-1.754-6.255-6.258-6.255-6.258-.144-.145-.334-.217-.524-.217-.193 0-.385.074-.532.221-.293.292-.295.766-.004 1.056l4.978 4.978h-14.692c-.414 0-.75.336-.75.75s.336.75.75.75h14.692l-4.979 4.979c-.289.289-.286.762.006 1.054.148.148.341.222.533.222.19 0 .378-.072.522-.215z" fill-rule="nonzero" /></svg>

    return (
        <div
            // style={_style?._bg}    
            className="_bg-slate-100 bg-black relative"
        >
            <div style={_style?._bg} className='absolute w-full h-full blur-2xl perfomance_fix'></div>
            <div className='absolute w-full h-full bg-black/60'></div>
            <div className="min-h-[200px] relative z-[15]">
                <div className="translate-y-[-100px]">
                    <ProtfolioCards projectList={projectList} />
                </div>
                <div className='relative z-[15]'>
                    <div>
                        <div className="mx-auto w-fit text-[30px] text-white _text-[#7880F4] font-bold text-center">
                            <Parallax offset={20} className="text-center">
                            Our web development journey so far
                            </Parallax>
                            <div className="py-[30px] sm:py-[30px] max-w-[500px] text-[16px] text-white font-normal text-center " >
                                <Parallax offset={20}>

                                Our showcase page features a range of web development projects that demonstrate our expertise across various industries and web development solutions.
                                 From e-commerce websites to online portfolios, our portfolio reflects the diverse needs of our clients and our ability to create custom solutions that meet their unique requirements.
                                  
                                </Parallax>
                            </div>
                            <div className='relative w-fit mx-auto pb-[20px] z-[50] cursor-pointer'>
                                {/* <Parallax offset={20}> */}
                                <Link to="/portfolio">
                                    <ArrowRight />
                                </Link>
                                {/* </Parallax> */}
                            </div>
                        </div>
                    </div>
                    <img src={Grass_home} className="w-[200px] absolute bottom-[-60px] left-0" />
                    <img src={Home_grasshome} className="w-[200px] absolute  hidden sm:block  bottom-[-60px] left-[100px] " />
                    <img src={Home_grasshome} className="w-[200px] absolute  hidden sm:block bottom-[-60px] right-[100px] " />
                    <img src={Grass_home} className="w-[200px] absolute bottom-[-60px] right-0" />
                </div>
            </div>
        </div>
    )
}
