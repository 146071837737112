import React from 'react'

import Home_tablet from 'Assets/home/monitor_home2.svg'
import ScreenImage from './screen_.jpg'
import Home_Play from 'Assets/home/Play_home.svg'
import donut_home from 'Assets/home/donut_home.svg'
import user_cardhome from 'Assets/home/user_cardhome.svg'
import userhome from 'Assets/home/userhome.svg'
import photo_home from 'Assets/home/photo_home.svg'
import Grass_home from 'Assets/home/Grass_home2.svg'
import Home_grasshome from 'Assets/home/Home_grasshome.svg'

import Parallax from 'Components/Parallax'

export default function HeroImages(props) {


    return (
        <>
            <div className="relative z-[20] ">
                <div className='absolute  | left-[20%] sm:left-[30%] lg:left-[0px] top-0 w-[300px] lg:w-[600px] | '>
                    <Parallax className='relative'>
                        <img src={Home_tablet} alt="" className=' pl-[10%] w-[90%]' />
                        <img src={ScreenImage} alt="" className='absolute left-[-20px] top-[30%]  w-[57%] rounded-2xl border-[5px] lg:border-[10px] border-white ' />
                    </Parallax>


                </div>
                <div className='absolute  | left-[50px] sm:left-[50%] lg:left-[200px] top-[0px]  lg:top-[50px] w-[50px] lg:w-[100px] | '>
                    <Parallax offset={200}>
                        <img src={userhome} alt="" />
                    </Parallax>
                </div>
                <Parallax offset={100}>
                    <img src={donut_home} alt="" className='absolute  | left-[-100px] sm:left-[10%] lg:left-[-220px] top-[-100px]  lg:top-[-170px] w-[200px] lg:w-[500px] | ' />
                </Parallax>
                <Parallax offset={50}>
                    <img src={user_cardhome} alt="" className='absolute   | right-[20%] sm:right-[10%] lg:right-[30px] top-[-30px] lg:top-[-10px] w-[200px] lg:w-[350px] | ' />
                </Parallax>
                <Parallax offset={100}>
                    <img src={userhome} alt="" className='absolute  | right-[100px] top-[0px] lg:top-[-0px] w-[70px] sm:w-[100px] | ' />
                </Parallax>
                <div className='absolute  | left-[170px] lg:left-[330px] top-[60px]  lg:top-[150px] w-[100px] lg:w-[200px] | '>
                    <Parallax offset={100}>
                        <img src={photo_home} alt="" />
                    </Parallax>
                </div>
                <div className='absolute  | left-[5%] sm:left-[20%] lg:left-[400px] top-[80px]  lg:top-[180px] w-[100px] lg:w-[200px] | '>
                    <Parallax offset={50}>
                        <img src={Grass_home} alt="" />
                    </Parallax>
                </div>
                <div className='absolute  | left-[30%] sm:left-[50%]  lg:left-[0px]  top-[70px] lg:top-[180px] w-[100px] lg:w-[200px] | '>
                    <Parallax offset={50}>
                        <img src={Home_grasshome} alt="" />
                    </Parallax>
                </div>

            </div>
        </>
    )
}
