
import { getImage } from 'Components/getImage';
import React, { useRef, useState } from 'react'


// swiper
import Swiper from 'react-id-swiper';
import SwiperCore, { Autoplay, Navigation } from 'swiper';
import 'swiper/css/bundle';

// import Card from './card'



export default function ProtfolioCards(props) {
    
    const { projectList = [] } = props
 

    const swiperRef = useRef(null);
    SwiperCore.use([Autoplay, Navigation]);

    const goNext = () => {
        if (swiperRef.current && swiperRef.current.swiper) {
            swiperRef.current.swiper.slideNext();
        }
    };

    const goPrev = () => {
        if (swiperRef.current && swiperRef.current.swiper) {
            swiperRef.current.swiper.slidePrev();
        }
    };

    const params = {
        // slidesPerView: 3,
        centeredSlides: true,
        slidesPerView: 2,
        loop: true,
        observer: true,
        spaceBetween: 50,
        on: {
            
        },
        autoplay: {
            delay: 2000,
            disableOnInteraction: false
        },
        breakpoints: {
            550: {
                slidesPerView: 3,
                spaceBetween: 50,
            }
        }

    }

    const RenderCard = (item, index) => {
        return (<div key={item.id} className='protfolio-card py-[30px] xl:px-[10px] relative '>
            
            <div className=" z-[15] hidden sm:block absolute w-[50px] h-[50px] rounded-xl bg-gradient-to-b from-[#58549C] to-[#878DFF]  translate-x-[50px] translate-y-[-10px] drop-shadow opacity-[1]"></div>
            {/* <div className=" z-[15] hidden sm:block absolute right-0 w-[70px] h-[70px] rounded-xl bg-gradient-to-b from-[#666DED] to-[#878DFF]  translate-x-[0px] translate-y-[10px] drop-shadow opacity-[1]"></div> */}
           
            <div className=" z-[15] hidden sm:block absolute bottom-0 w-[60px] h-[60px] rounded-xl bg-gradient-to-b from-[#58549C] to-[#878DFF]  translate-x-[0px] translate-y-[-50px] drop-shadow opacity-[1]"></div>
            
            <div className='relative z-[14] protfolio-card-child bg-[#7880F4] border-[5px]  sm:border-[8px] border-white  _border-[#919bf8] w-[100%]  drop-shadow-xl rounded-[5px]'>
                {/* <img src={getImage(item?.image,"/images/projects/project_1_starworld.png")} className=" h-full" alt="" /> */}
                <img src={item?.image} className=" h-fit h-[200px] max-h-[100px] sm:max-h-[200px] sm:h-[200px] lg:h-[300px] lg:max-h-[300px]" alt="" />
                <div className="absolute hidden sm:block text-white right-[10px] top-[30px] z-[16]  bg-gradient-to-b from-[#58549C] to-[#A8CF45]  p-[10px] rounded-sm">
                    {item.label}
                </div>
            </div>

        </div>)
    }


    return (
        <div className='w-full overflow-hidden '>
           {
            projectList.length > 0 &&
                <Swiper {...params} ref={swiperRef}>
                    {projectList.map(RenderCard)}
                </Swiper>
           }
        </div>
    )
}
