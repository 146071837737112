import React, {useContext} from 'react'

import { Store } from './websiteDataStore'

export default function GetData(props) {
    
    const storeData = useContext(Store)

    return {storeData : storeData?.state || {} }
}
