
import './App.css';

import RoutesRender from './routes';
// store
import { StateProvider as WebsiteDataProviders } from 'store/websiteDataStore'

function App() {
  return (
    <div className="App w-full overlfow-x-hidden">
      <WebsiteDataProviders>
        <RoutesRender />
      </WebsiteDataProviders>
    </div>
  );
}

export default App;
