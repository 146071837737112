import React from 'react'

export default function View(props) {
    
    const _stackList = [
        {
            id:1,
            name : "React.js"
        },
        {
            id:2,
            name : "node.js"
        },
        {
            id:8,
            name : "next.js"
        },
        {
            id:3,
            name : "strapi"
        },
        {
            id:4,
            name : "stripe payments"
        },
        {
            id:5,
            name : "HTML"
        },
        {
            id:6,
            name : "CSS"
        },
        {
            id:7,
            name : "JavaScript"
        },
    ]

    const _solutions = [
        {
            id : 1,
            name : "Strapi Admin Dash",
            description : "Using the Strapi dashboard for your website page can enable you to easily create, manage and update content for your website. With Strapi, you can create custom content types, add fields and media, and define relationships between different content types.",
            image : "/images/solutions/solution_1_admin.png"
        },
        {
            id : 2,
            name : "Stripe Payments",
            description : "Stripe is a payment processing platform that allows businesses to accept payments online. By integrating Stripe into a website, businesses can offer their customers a seamless and secure payment experience. ",
            image : "/images/solutions/solution_2_payment.png"
        },

    ]

    const SolutionCard = ({item = {}}) => {
        return(<div className='my-[30px] w-full py-[20px] px-[20px] bg-gradient-to-b from-[#494682] via-[#494682] to-[#494682] rounded-lg  max-w-2xl shadow'>
            <div className="flex flex-wrap sm:flex-nowrap">
                <div className="w-[250px] h-[150px] bg-[#7780f4] rounded-lg ">
                    <img src={item.image} alt={item.name} className=" sm:min-w-[250px]  w-full h-full  rounded-lg" />
                </div>
                <div className=" grow px-[20px] text-[14px] text-white mt-[20px] sm:mt-[0px] ">
                    <div className="text-[20px] font-bold">
                        {item?.name}
                    </div>
                    <div className="pt-[10px]">
                        {item.description}
                    </div>
                </div>
            </div>
        </div>)
    }

    return (
        <>
            <div className="pt-[50px]">
                <div className="text-[16px] text-white font-bold">
                    Tech stacks & Solutions
                </div>
                <div className="pt-[30px]">
                    <div className="flex flex-wrap">
                        {
                            _stackList.map(item => 
                            <div key={item.id} className="w-fit m-2 p-2 text-white bg-black/30 backdrop-blur-sm rounded-lg mr-[10px] ">
                                {item.name}
                            </div>)
                        }
                    </div>
                </div>
                <div className="pt-[0px]">
                    {
                        _solutions.map(item => {
                            return(<div key={item.id}>
                                <SolutionCard item={item} />
                            </div>)
                        })
                    }
                </div>
            </div>
        </>
    )
}
