import React from 'react'

import Parallax from 'Components/Parallax'
import Form from './Form'
import Contact from './Contact'
import Footer from './Footer'

import { SlideLeft, SlideLeftLong, SlideRightLong } from 'Components/SlideAnimation'


export default function Index(props) {

    

    return (
        <>
            <div 
                id="contact"
                className="relative  bg-gradient-to-b from-[#58549C] to-[#58549C] w-full overflow-hidden">
                <SlideRightLong className="z-[1] absolute w-[60%] h-full  bg-gradient-to-r from-[#837DE8] to-[#494682] "></SlideRightLong>
                <SlideRightLong className="z-[2] absolute w-[40%] h-full  bg-gradient-to-r from-[#34325C] to-[#494682] "></SlideRightLong>
                
                <SlideRightLong className="z-[3] absolute w-[25%] h-full  bg-gradient-to-r from-[#837DE8] to-[#5F5BA8] "></SlideRightLong>
                <SlideRightLong className="z-[4] absolute w-[15%] h-full  bg-gradient-to-r from-[#5F5BA8] to-[#837DE8] "></SlideRightLong>

                <SlideLeftLong className="z-[5] absolute w-[600px] h-[600px] right-[-200px] top-[-200px] rounded-full  bg-gradient-to-l from-[#58549C] via-[#5F5BA8] to-[#837DE8] to-[#837DE8] "></SlideLeftLong>
                <SlideLeftLong className="z-[6] absolute w-[500px] h-[500px] right-[-200px] top-[-190px] rounded-full  bg-gradient-to-l from-[#58549C] via-[#5F5BA8] to-[#837DE8] to-[#837DE8] "></SlideLeftLong>
                <SlideLeftLong className="z-[7] absolute w-[400px] h-[400px] right-[-200px] top-[-150px] rounded-full  bg-gradient-to-l from-[#58549C] via-[#5F5BA8] to-[#837DE8] to-[#837DE8] "></SlideLeftLong>


                <div className="relative z-[10] container mx-auto px-[20px] sm:px-[50px] ">

                    <div className="">
                        <div className="pt-[50px]">
                            <div offset={40} className="text-[40px] font-bold text-white">
                                Let's talk
                            </div>
                            <div className=" max-w-[350px] text-white leading-[30px] font-bold pt-[20px]">
                                Write about your requirements
                                and we will contact you.
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-wrap py-[20px] ">
                        <div className="w-full  sm:w-fit pt-[0px]  sm:min-w-[500px]  ">
                            
                            <div className="text-[20px] bg-[#34325C]/80 w-full p-[20px] rounded-xl backdrop-blur-xl shadow">
                                <Form />
                                
                            </div>
                            
                        </div>

                        <div className="w-full lg:w-1/2 sm:pt-[50px] xl:pt-0 px-[10px]">
                        <div className=" bg-[#34325C] mt-[50px]  sm:mt-[0px]  sm:ml-[0px] lg:ml-[20px] p-[20px]  rounded-2xl w-full sm:w-[300px] shadow ">
                                <Contact />
                            </div>
                          

                        </div>
                    </div>
                </div>
                <div className="pt-[100px]"></div>
            </div>
            <Footer />
        </>
    )
}
