import React, {createContext, useReducer} from 'react';
// import { _carsList } from 'Pages/_carsList';

// import { homePage } from 'pages/Home/data';

const stateModal = {
    homePage : {},
    newsList : {},
    
  }
  
  const initialState = {...stateModal};

  const Store = createContext(initialState);

  const { Provider } = Store;


const StateProvider = ( { children } ) => {
    const [state, dispatch] = useReducer((state, action) => {
      let newState = {}
      // console.log(action)
      switch(action.type) {
        case 'initAll':
            newState = {
                ...state, 
                homePage : { 
                    ...state?.homePage, 
                    ...action?.payload?.homePage,
                },
                // newsList : {
                //   ...state?.newsPage, 
                //   ...action?.payload?.newsPage,
                // },
                
            }
            return newState;
        
        case 'clearState' :
          return {  ...stateModal  }
        default:
          throw new Error();
      };
    }, initialState);
  
    return <Provider value={{ state, dispatch }}>{children}</Provider>;
  };
  

  export { Store , StateProvider }