import React, { useEffect } from 'react'
import { useLocation, useParams } from 'react-router-dom';
import { useScrollBehaviours } from 'Components/useScrollBehaviours'

import Footer from 'Pages/Home/Contact/Footer';
import Navbar from 'Components/Navbar'

import {  SlideLeftLong, SlideTop } from 'Components/SlideAnimation'
import List from './List';

import useStoreData from 'store/storeData'
import View from './View';
import  "./view.css"


export default function Index(props) {
    
    const location = useLocation()
    const { ScrollToTop } = useScrollBehaviours()
    const { id } = useParams()

    const { storeData } = useStoreData()
    const blogList = storeData?.homePage?.blogs || []

    console.log(blogList)

    useEffect(() => {
        if (location.hash.slice(1)) {
            let elem = document.getElementById(location.hash.slice(1))
            if (elem) {
                elem.scrollIntoView({ behavior: "smooth" })
            }
        } else {

            ScrollToTop()
        }
        // ScrollToTop()
    }, [location])


    return (
        <>
            <div className='w-full overflow-x-hidden'>
            <Navbar />
            <div className="relative min-h-[100vh] h-fit bg-gradient-to-r from-[#58549C] to-[#34325C] w-full overflow-hidden">
                 
                <SlideLeftLong className="z-[5] absolute w-[600px] h-[600px] right-[-200px] bottom-[-150px] rounded-full  bg-gradient-to-r from-[#58549C] via-[#837DE8] to-[#58549C] "></SlideLeftLong>
                <SlideLeftLong className="z-[6] absolute w-[500px] h-[500px] right-[-200px] bottom-[-150px] rounded-full   bg-gradient-to-r from-[#58549C] via-[#837DE8] to-[#58549C] "></SlideLeftLong>
                <SlideLeftLong className="z-[7] absolute w-[400px] h-[400px] right-[-200px] bottom-[-150px] rounded-full   bg-gradient-to-r from-[#58549C] via-[#837DE8] to-[#58549C] " ></SlideLeftLong>
               
                <SlideLeftLong className="z-[5] absolute w-[600px] h-[600px] top-[-200px] left-[-150px] rounded-full   bg-gradient-to-l from-[#58549C] via-[#837DE8] to-[#58549C] "></SlideLeftLong>
                <SlideLeftLong className="z-[6] absolute w-[500px] h-[500px] top-[-200px] left-[-150px] rounded-full   bg-gradient-to-l from-[#58549C] via-[#837DE8] to-[#58549C] "></SlideLeftLong>
                <SlideLeftLong className="z-[7] absolute w-[400px] h-[400px] top-[-200px] left-[-150px] rounded-full   bg-gradient-to-l from-[#58549C] via-[#837DE8] to-[#58549C] "></SlideLeftLong>
                
                <div className="relative z-[10] container mx-auto px-[50px] pt-[150px]">
                    {
                        id ? <View id={id} /> : null
                    }
                    <div className="text-[30px] font-bold text-white">
                        Blogs
                    </div>
                    <div className="py-[50px]">
                        <List blogList={blogList} />
                    </div>
                 
                </div>
            </div>
         </div>
         <Footer />
        </>
    )
}
