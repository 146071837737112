import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'


export default function SideBar(props) {

    const { navData = {}, closeSidebar = () => { }, open = false } = props

    const [expand, setExpand] = useState(false)

    const navigate = useNavigate()

    const redirect = (url) => {
        closeSidebar()
        navigate(url)
    }

    return (
        <>
            {open && <div onClick={() => closeSidebar()} className="fixed z-[300] h-full w-full left-0 top-0  "></div>}
            <div className={`${open ? "translate-x-0" : "translate-x-[110%]"}  transition-transform duration-1000 fixed z-[301] h-full w-full right-0 top-0  h-full  min-w-[300px]  px-[50px] bg-[#7780f4]/50 backdrop-blur-lg `}>
                <div className=' mr-0 pt-[40px]' >
                    <div
                        onClick={() => closeSidebar()}
                        className="cursor-pointer relative w-[30px] h-[30px] flex flex-col justify-between ml-auto  pt-[15px]">
                        <div className={`absolute h-[5px] w-[30px] bg-white rotate-[135deg]`}></div>
                        <div className={`absolute h-[5px] w-[30px] bg-white rotate-[45deg]`}></div>
                    </div>
                </div>
                <div className="flex flex-col items-start ">
                    {navData?.nav_links?.map((item) => {
                        return (
                            <div 
                                onClick={() => redirect(`${item.link}`)}
                                key={item?.label} className={`pb-[40px] inter text-[24px] font-[500]  cursor-pointer  text-white`}>
                                {item.label}
                            </div>
                        )
                    })}
                </div>
            </div>
        </>
    )
}
