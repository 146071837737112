import React, { useState, useEffect } from 'react'
// import { _data } from '../data'

// libs
import { motion } from 'framer-motion'
import { useLocation, useNavigate } from 'react-router-dom'

export default function NavLinks(props) {

    const { navData, openSideBar = () => { } } = props
    const _navbar = navData || {}
    // console.log(_navbar)
    const location = useLocation()

    const [isUnderlined, setUnderlined] = useState("/")

    const makeUnderlined = (id) => {
        setUnderlined(id)
    }

    const resetUnderlined = (delay = 0) => {
        setTimeout(() => {
            setUnderlined(location.pathname)
        }, delay)
    }

    useEffect(() => {
        resetUnderlined()
    }, [])

    const navigate = useNavigate()

    return (
        <div className='grow max-w-[500px] sm:py-[20px]  rounded-xl relative relative z-[20] '>
            <div className='hidden lg:flex   justify-between' onMouseLeave={() => resetUnderlined(500)}>
                {_navbar?.nav_links?.map(item => (
                    <div key={item.id}
                        onMouseEnter={() => makeUnderlined(item.link)}
                        onClick={() => navigate(item.link)}
                    >
                        <div className="relative font-bold text-white cursor-pointer">
                            {item.label}
                            {
                                isUnderlined === item.link &&
                                <motion.div
                                    layoutId="navlink-underline"
                                    className="absolute top-[30px] rounded-xl shadow left-0 h-1 w-full bg-white"></motion.div>
                            }
                        </div>
                    </div>
                ))}
            </div>
            <div className=" lg:hidden">
                <div
                    onClick={() => openSideBar()}
                    className="w-[50px] h-[50px] cursor-pointer">
                    <svg fill='white' clip-rule="evenodd" fill-rule="evenodd" stroke-linejoin="round" stroke-miterlimit="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="m13 16.745c0-.414-.336-.75-.75-.75h-9.5c-.414 0-.75.336-.75.75s.336.75.75.75h9.5c.414 0 .75-.336.75-.75zm9-5c0-.414-.336-.75-.75-.75h-18.5c-.414 0-.75.336-.75.75s.336.75.75.75h18.5c.414 0 .75-.336.75-.75zm-4-5c0-.414-.336-.75-.75-.75h-14.5c-.414 0-.75.336-.75.75s.336.75.75.75h14.5c.414 0 .75-.336.75-.75z" fill-rule="nonzero" /></svg>
                </div>
            </div>

        </div>
    )
}
