export const  _data = {
    navbar : {
        logo : '',
        nav_links : [
            {
                id : 1,
                label : "HOME",
                link : "/"
            },
            {
                id : 3,
                label : "PORTFOLIO",
                link : "/portfolio"
            },
            {
                id : 4,
                label : "PRICING",
                link : "/pricing"
            },
            {
                id : 5,
                label : "CONTACT",
                link : "/#contact"
            },
        ]
    },
    home : {
        hero : {
            heading : "We build websites that make a difference",
            description : "Looking for more information? Send us your requirements, and we'll provide all the details you need."
        },
        projectLists : [
            {
                id : 4,
                label : "Bklik",
                image : "/images/projects/project_4_bklik.png"
            },
            {
                id : 1,
                label : "Starworld Limo",
                image : "/images/projects/project_1_starworld.png"
            },
            {
                id : 2,
                label : "Dimension",
                image : "/images/projects/project_2_dimension.png"
            },
            {
                id : 3,
                label : "Bana",
                image : "/images/projects/project_3_bana.png"
            },
            {
                id : 5,
                label : "TedxCUSAT",
                image : "/images/projects/project10.png"
            },
            {
                id : 6,
                label : "Innovators Summit",
                image : "/images/projects/project6.png"
            },
            {
                id : 7,
                label : "ASFE",
                image : "/images/projects/project7.png"
            },
            {
                id : 8,
                label : "TrailHeist",
                image : "/images/projects/project8.png"
            },
            {
                id : 9,
                label : "Elephant paradise",
                image : "/images/projects/project5.png"
            },
            {
                id : 10,
                label : "",
                image : "/images/projects/project11.png"
            },
            {
                id : 11,
                label : "",
                image : "/images/projects/project9.png"
            },
           
        ],
        services : {
            serviceList : [
                {
                    id :1,
                    label : "Web Development"
                },
                {
                    id :2,
                    label : "Web Design"
                },
                {
                    id :3,
                    label : "Telegram bot"
                },
                {
                    id :4,
                    label : "Shopify website"
                },
                
            ]
        },
        blogs : [
            {
                id : 1,
                thumbnail : '/images/projects/project_3_bana.png'
            },
            {
                id : 2,
                thumbnail : '/images/projects/project_3_bana.png'
            },
            {
                id : 3,
                thumbnail : '/images/projects/project_3_bana.png'
            },
            {
                id : 4,
                thumbnail : '/images/projects/project_3_bana.png'
            },
        ]

    }
}