import React from 'react'

import { _data } from '../Home/data'
import ProtfolioCards from 'Components/ProtfolioCards'
import { JustAppear, SlideTop } from 'Components/SlideAnimation'


export default function ProjectList(props) {
    
    const projectList = _data?.home?.projectLists || []

    const RenderCard = ({item}) => {
        return (<SlideTop key={item.id} className='max-w-[300px]'>
                    
            <div className='relative z-[14] protfolio-card-child bg-[#7880F4] border-[5px]  lg:border-[10px] border-white  _border-[#919bf8] w-[100%]  drop-shadow-xl rounded-[5px] max-h-[250px] sm:max-h-fit'>
                <img src={item?.image} className="h-[150px] sm:max-h-fit " alt="" />
            </div>

        </SlideTop>)
    }

    return (
        <>
        <div className="pt-[50px]">
            {/* <ProtfolioCards projectList={projectList} /> */}
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6">

            {
                projectList?.map(item => {
                    return(<div>
                        <RenderCard item={item} />
                    </div>)
                })
            }
            </div>
        </div>            
        </>
    )
}
