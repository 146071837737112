import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom';
import { useScrollBehaviours } from 'Components/useScrollBehaviours'

import Navbar from 'Components/Navbar'
import { JustAppear, SlideBottom, SlideRightLong, SlideLeftLong, SlideTop } from 'Components/SlideAnimation'
import Packages from './Packages'
import Quote from './Quote'
import Footer from 'Pages/Home/Contact/Footer';



export default function Index(props) {


    const location = useLocation()
    const { ScrollToTop } = useScrollBehaviours()

    useEffect(() => {
        // if (location.hash.slice(1)) {
        //     let elem = document.getElementById(location.hash.slice(1))
        //     if (elem) {
        //         elem.scrollIntoView({ behavior: "smooth" })
        //     }
        // } else {

        //     ScrollToTop()
        // }
        ScrollToTop()
    }, [location])


    return (
        <>
            <div className='w-full overflow-x-hidden'>
                <Navbar />
                <div className="relative min-h-[100vh] h-fit bg-gradient-to-r from-[#5F5BA8] to-[#837DE8] w-full overflow-hidden">
                    <SlideLeftLong className="z-[5] absolute w-[600px] h-[600px] right-[-200px] bottom-[-150px] rounded-full   bg-gradient-to-r from-[#837DE8] via-[#5F5BA8] to-[#837DE8]  "></SlideLeftLong>
                    <SlideLeftLong className="z-[6] absolute w-[500px] h-[500px] right-[-200px] bottom-[-150px] rounded-full  bg-gradient-to-r from-[#837DE8] via-[#5F5BA8] to-[#837DE8]  "></SlideLeftLong>
                    <SlideLeftLong className="z-[7] absolute w-[400px] h-[400px] right-[-200px] bottom-[-150px] rounded-full  bg-gradient-to-r from-[#837DE8] via-[#5F5BA8] to-[#837DE8]  " ></SlideLeftLong>

                    <SlideLeftLong className="z-[5] absolute w-[600px] h-[600px] top-[-200px] left-[-150px] rounded-full  bg-gradient-to-r from-[#837DE8] via-[#5F5BA8] to-[#837DE8]  "></SlideLeftLong>
                    <SlideLeftLong className="z-[6] absolute w-[500px] h-[500px] top-[-200px] left-[-150px] rounded-full  bg-gradient-to-r from-[#837DE8] via-[#5F5BA8] to-[#837DE8]  "></SlideLeftLong>
                    <SlideLeftLong className="z-[7] absolute w-[400px] h-[400px] top-[-200px] left-[-150px] rounded-full  bg-gradient-to-r from-[#837DE8] via-[#5F5BA8] to-[#837DE8]  "></SlideLeftLong>
                    <div className="relative z-[10] container mx-auto px-[50px] pt-[150px]">
                        {/* <div className="text-[30px] font-bold text-white pb-[30px] relative w-fit">
                            Packages
                            <div className="absolute h-[8px] w-full bg-[#A8CF45] mt-[10px]"></div>
                        </div>
                        <div className="">
                            <Packages />
                        </div> */}
                        <div className="mt-[0px]"></div>
                        <div id="quote" className="text-[30px] font-bold text-white mt-[50px] ">
                            Get Pricing quote
                        </div>
                        <div className="px-[10px]">

                        <div className="mt-[30px]  w-fit p-[20px] pb-[20px] rounded-lg bg-gradient-to-b from-[#494682] via-[#494682] to-[#494682] shadow">
                            <div className="">
                                <Quote />
                            </div>
                        </div>
                        </div>
                        <div className="pb-[200px]"></div>
                    </div>
                    
                </div>
                

            </div>
            <Footer />
        </>
    )
}
