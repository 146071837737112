import React, {useState} from 'react'

import usescrollpos from 'Components/useScrollPos'


import Desktop from './Desktop'
import Mobile from './Mobile'
import SideBar from './SideBar'

import { _data } from 'Pages/Home/data'

// import useStoreData from 'store/storeData'

export default function Index(props) {

    const { isScrollBeyond } = usescrollpos()

    const floatActivate = isScrollBeyond(80)
    const [open, setOpen] = useState(false)
    const navbar = _data.navbar

    const openSideBar = () => {
        setOpen(true)
    }

    const closeSidebar = () => {
        setOpen(false)
    }

    return (
        <>
        <div className="">
           <Desktop {...props} navData={navbar} openSideBar={openSideBar}   transparent={false}  isfloatingNav={true} floatActivate={floatActivate} /> 
           {/* <Desktop {...props}  navData={navbar} openSideBar={openSideBar} />  */}
        </div>
        {/* <div className="block md:hidden"> */}
           {/* <Mobile {...props}  openSideBar={openSideBar} transparent={false}  isfloatingNav={true} floatActivate={floatActivate} />  */}
           {/* <Mobile {...props}  openSideBar={openSideBar} />  */}
        {/* </div> */}
        <SideBar navData={navbar} open={open}  closeSidebar={closeSidebar} />
        </>
    )
}
