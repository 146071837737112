import React, { useContext, useEffect, useState } from 'react'
import { Routes, Route } from "react-router-dom";
import { Store } from 'store/websiteDataStore'

import { Dna } from 'react-loader-spinner'
import axios from 'axios';

import Home from './Pages/Home'
import Projects from './Pages/Projects'
import Pricing from './Pages/Pricing'
import Blog from './Pages/Blog'

// import { Dna } from 'react-loader-spinner'


export default function RoutesRender(props) {

    const _store = useContext(Store)
    const [loading, setLoading] = useState(true)
    const [error, seterror] = useState(false)

    const routesList = [
        {
            path: '/',
            component: <Home />
        },
        {
            path: '/portfolio',
            component: <Projects />
        },
        {
            path: '/pricing',
            component: <Pricing />
        },
        {
            path: '/blog',
            component: <Blog />
        },
        {
            path: '/blog/:id',
            component: <Blog />
        },
        {
            path: '*',
            component: <Home />
        },
    ]

    const _URL = "http://localhost:1337"

    const fetchAlldata = async () => {
        try {
            setLoading(true)
            const res = await axios.get(`${process.env.REACT_APP_URL || _URL}/api/home-page/getAllconfigs`)
            // const data = await res.json()
            // console.log(res)
            _store.dispatch(
                {
                    type: 'initAll',
                    payload: res?.data?.data
                }
            )
            setLoading(false)
        } catch (ex) {
            seterror(true)
            setLoading(false)
        }
    }



    useEffect(() => {
        // fetchAlldata()
    }, [])

    useEffect(() => {
        const delayedFunction = setTimeout(() => {
            setLoading(false)
        }, 2000);

       
        return () => {
            clearTimeout(delayedFunction);
        };

    }, []); 

    return (<>
        {loading ?
            <div className='py-[0px] w-full h-screen flex flex-col items-center justify-center'>
                <Dna
                    height="60"
                    width="60"
                    // color="#595EA4"
                    // secondaryColor='#7CC7DE'
                    radius='12.5'
                    ariaLabel="mutating-dots-loading"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={true}
                />
            </div>
            :
            <Routes>
                {routesList.map(link => <Route key={link.path} path={link.path} element={link.component} />)}
            </Routes>
        }
    </>)
}