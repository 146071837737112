import React, {useEffect} from 'react'
import { _data } from '../data'
import HeroImages from './HeroImages'
import Logo from './Logo'
import NavLinks from './NavLinks'
import PrimaryButton from 'Components/PrimaryButton'

import { useLocation } from 'react-router-dom';
import { useScrollBehaviours } from 'Components/useScrollBehaviours'

// import {useCurrentWidth} from './useResize'
import MarkdownView from 'react-showdown';
import { JustAppear, SlideBottom, SlideRightLong, SlideLeftLong, SlideTop } from 'Components/SlideAnimation'
import { useNavigate } from 'react-router-dom'

import { bg_colors_secondary } from 'Components/Bg'

export default function Hero(props) {

    // let width = useCurrentWidth();
    const _hero = _data?.home?.hero || {}
    const navigate = useNavigate()

    const location = useLocation()
    const { ScrollToTop } = useScrollBehaviours()

    

    useEffect(() => {
        if (location.hash.slice(1)) {
            let elem = document.getElementById(location.hash.slice(1))
            if (elem) {
                elem.scrollIntoView({ behavior: "smooth" })
            }
        } else {

            ScrollToTop()
        }
        // ScrollToTop()
    }, [location])


    return (
        <>
            <div className={`relative  sm:min-h-[1000px]  lg:min-h-[850px] sm:max-h-[900px] lg:h-[120vh] bg-gradient-to-r from-[#58549C] to-[#58549C] w-full`}>
                {/* bg stripes on left */}
                <SlideRightLong className="z-[1] absolute w-1/2 h-full  bg-gradient-to-r  from-[#837DE8] to-[#34325C]"></SlideRightLong>
                <SlideRightLong className="z-[2] absolute w-1/3 h-full  bg-gradient-to-r   from-[#34325C] to-[#5F5BA8] "></SlideRightLong>
                <SlideRightLong className="z-[3] absolute w-[20%] h-full  bg-gradient-to-r from-[#5F5BA8] to-[#837DE8] "></SlideRightLong>
                <SlideRightLong className="z-[4] absolute w-[10%] h-full  bg-gradient-to-r from-[#5F5BA8] to-[#837DE8] "></SlideRightLong>
                
                <SlideLeftLong className="z-[5] absolute w-[600px] h-[600px] right-[-200px] bottom-[-150px] rounded-full  bg-gradient-to-l from-[#58549C] via-[#5F5BA8] to-[#837DE8] "></SlideLeftLong>
                <SlideLeftLong className="z-[6] absolute w-[500px] h-[500px] right-[-200px] bottom-[-150px] rounded-full  bg-gradient-to-l from-[#58549C] via-[#5F5BA8] to-[#837DE8] "></SlideLeftLong>
                <SlideLeftLong className="z-[7] absolute w-[400px] h-[400px] right-[-200px] bottom-[-150px] rounded-full  bg-gradient-to-l from-[#58549C] via-[#5F5BA8] to-[#837DE8] "></SlideLeftLong>
                
                <div className="z-[20] relative h-full w-full letf-0 top-0">

                    <div className=" container mx-auto px-[50px]">
                        {/* Navigation */}
                        {/* <div className="flex justify-between py-[20px] items-center">
                    <NavLinks />
                    <Logo />
                </div> */}
                        {/* Content */}
                        <div className="flex flex-wrap lg:flex-nowrap pt-[200px]">
                            <div className="order-2 lg:order-1 w-full lg:w-1/2 lg:min-w-[550px] xl:min-w-[650px]">
                                {/* {width} */}
                                <div className="min-h-[400px] pt-[100px] lg:pt-0">
                                    <HeroImages />
                                </div>
                            </div>
                            <div className="order-1 lg:order-2 w-full  lg:w-1/2 text-white">
                                <div className=" lg:pl-[40px] text-center lg:text-left">
                                    <div className="pt-[0px]   lg:pt-[0] text-[30px] lg:text-[40px] font-bold max-w-[400px] lg:max-w-[500px] mx-auto lg:ml-0">
                                        <SlideTop>
                                            <MarkdownView markdown={_hero?.heading} />
                                        </SlideTop>
                                    </div>
                                    <div className="pt-[20px] max-w-[350px]  lg:max-w-[350px] mx-auto lg:ml-0 leading-[30px]">
                                        <JustAppear>
                                            <MarkdownView markdown={_hero?.description} />
                                        </JustAppear>
                                        <div className='pt-[20px] w-fit mx-auto lg:ml-0'>
                                            <SlideBottom>
                                                <PrimaryButton 
                                                    onClick={() => navigate(`/#contact`) }
                                                    label="GET A QUOTE" />
                                            </SlideBottom>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="flex justify-center items-center w-full mt-[100px] translate-y-[-120px] sm:translate-y-[-50px]">
                            <div className="w-fit animate-bounce">
                                <svg xmlns="http://www.w3.org/2000/svg" fill='white' width="24" height="24" viewBox="0 0 24 24"><path d="M0 7.33l2.829-2.83 9.175 9.339 9.167-9.339 2.829 2.83-11.996 12.17z" /></svg>
                            </div>
                        </div>

                    </div>
                </div>

            </div>
        </>
    )
}

